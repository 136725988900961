<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="4">
        <b-card>
          <b-form-group
            id="input-group-name"
            label="Огноо:"
            label-for="input-name"
            description="Хамгийн ихдээ 90 хоног сонгоно уу"
          >
            <date-range-picker
              class="w-100"
              v-model="dateRange"
              :locale-data="locale"
              :opens="opens"
              :autoApply="true"
              :ranges="ranges"
              :timePicker="false"
            >
            </date-range-picker>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col sm="8">
        <b-card>
          <b-list-group>
            <ReportItem
              style="border-radius: 0"
              v-for="(_report, index) in reports.datas"
              :key="index"
              :dateRange="dateRange"
              :name="_report.name"
              :slug="_report.slug"
              :header="_report.config.header"
              :index="new String(index + 1)"
              :children="_report.hasOwnProperty('child') ? _report.child : []"
            >
            </ReportItem>
            <ReportItemNew
              style="border-radius: 0"
              v-for="(_report, index) in reports_new.datas"
              :key="index + reports.datas.length"
              :dateRange="dateRange"
              :name="_report.name"
              :slug="_report.slug"
              :header="_report.config.header"
              :index="new String(index + 1 + reports.datas.length)"
              :children="_report.hasOwnProperty('child') ? _report.child : []"
            >
            </ReportItemNew>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";
import ReportItem from "./ReportItem";
import ReportItemNew from "./ReportItemNew";
import reports from "./_reports";
import reports_new from "./_reports_new";

export default {
  name: "Report.list",
  components: {
    DateRangePicker,
    axios,
    ReportItem,
    ReportItemNew,
  },
  data: function() {
    return {
      alwaysShowCalendars: false,
      linkedCalendars: false,
      ranges: {
        Өнөөдөр: [moment(), moment()],
        Өчигдөр: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Сүүлийн 30 хоног": [moment().subtract(29, "days"), moment()],
        "Сүүлийн 90 хоног": [moment().subtract(89, "days"), moment()],
        "Энэ сар": [moment().startOf("month"), moment().endOf("month")],
        "Өмнөх сар": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month"),
        ],
      },
      dateRange: {
        startDate: moment()
          .subtract(89, "days")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      opens: "right",
      locale: {
        separator: " - ",
        format: "YYYY-MM-DD",
        customRangeLabel: "Өдөр сонгох",
        daysOfWeek: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя"],
        monthNames: [
          "1-р сар",
          "2-р сар",
          "3-р сар",
          "4-р сар",
          "5-р сар",
          "6-р сар",
          "7-р сар",
          "8-р сар",
          "9-р сар",
          "10-р сар",
          "11-р сар",
          "12-р сар",
        ],
        firstDay: 1,
      },
      reports: reports.list,
      reports_new: reports_new.list,
    };
  },
  methods: {},
};
</script>
